import React, { ChangeEvent } from 'react';

import { IFocusArea } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getName, getOwners, getSkills, getErrors } from '~/selectors/currentGoal';
import { setCurrentGoalName, setCurrentGoalSkills } from '~/store/currentGoal/actions';

import {
  Error,
  ItemContainer,
  ItemLabel,
  ItemRow,
  SkillNameIcon,
  SkillNameText,
  SkillNameWrap,
  SuggestionBtnWrap,
} from '../../stepsDesign';
import GoalSuggestionModal from '../GoalSuggestionsModal';

const StyledButton = styled(Button)`
  max-width: 115px;
`;

const NameSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const name = useSelector(getName);
  const skills = useSelector(getSkills);
  const owners = useSelector(getOwners);
  const $isSuggestionModalVisible = useBoolState(false);
  const errors = useSelector(getErrors);
  const getMultiLangString = useMultiLangString();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    dispatch(setCurrentGoalName(e.target.value));
  };

  const onRemoveSkill = () => {
    // @ts-ignore
    dispatch(setCurrentGoalSkills([]));
  };

  const getMarginBottom = () => {
    if (errors && errors.name) {
      return '0px';
    }

    if (skills && skills.length > 0) {
      return '10px';
    }

    return '25px';
  };

  return (
    <div>
      <ItemRow marginTop="16px" marginBottom={getMarginBottom()}>
        <ItemContainer>
          <ItemLabel>
            <Trans>Goal name</Trans>
          </ItemLabel>
          <Input
            placeholder={i18n._(t`The name of the goal`)}
            value={name || ''}
            onChange={onChange}
            width="540px"
            height="38px"
            error={errors && !!errors.name}
          />
        </ItemContainer>

        <ItemContainer>
          <SuggestionBtnWrap>
            <StyledButton
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={$isSuggestionModalVisible.on}
              label={i18n._(t`Suggestions`)}
            />
          </SuggestionBtnWrap>
        </ItemContainer>

        {$isSuggestionModalVisible.value && owners && (
          <GoalSuggestionModal
            userId={owners && owners.length > 0 && owners[0]}
            onClose={$isSuggestionModalVisible.off}
            onSelectSuggestion={(focusArea: IFocusArea) => {
              $isSuggestionModalVisible.off();
              // @ts-ignore
              dispatch(setCurrentGoalName(getMultiLangString(focusArea?.name)));
              // we need transform this into skill IDs array before api call
              dispatch(
                // TO DO: adapt to new skill schema
                // @ts-ignore
                setCurrentGoalSkills([
                  {
                    id: focusArea?.skill.id,
                    name: getMultiLangString(focusArea?.skill.name),
                  },
                ]),
              );
            }}
          />
        )}
      </ItemRow>
      {errors && errors.name && <Error>{errors.name}</Error>}
      {skills && skills.length > 0 && (
        <ItemRow marginBottom="0px">
          <ItemContainer>
            <SkillNameWrap>
              <SkillNameText>{getMultiLangString(skills[0].name)}</SkillNameText>
              <SkillNameIcon>
                <Icon icon={ICONS.CLOSE} size={ICON_SIZES.SMALL} onClick={onRemoveSkill} />
              </SkillNameIcon>
            </SkillNameWrap>
          </ItemContainer>
        </ItemRow>
      )}
    </div>
  );
};

export { NameSection };
