import { AxiosError } from 'axios';
import { useHistory } from 'react-router';

import routes from '~/constants/routes';
import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getReviewTask, getReviewTaskOutside } from '~/services/tasks';

import type { IPopulatedReviewTask, IPopulatedUserReview } from './types';
import type { IUser, WithReplace } from '@learned/types';

const useReviewTask = ({ reviewTaskId }: { reviewTaskId: string }) => {
  const history = useHistory();

  const [{ reviewTask, userReview }, isLoading] = useAsyncFetch<{
    reviewTask?: IPopulatedReviewTask;
    userReview?: IPopulatedUserReview;
  }>({
    fetch: async () => {
      let res = {};
      try {
        const response = await getReviewTask(reviewTaskId);

        res = {
          reviewTask: response.data.task,
          userReview: response.data.userReview,
        };
      } catch (error) {
        const status = (error as AxiosError)?.response?.status;
        if (status && [403, 404].includes(status)) {
          history.replace(routes.ERROR + '?errorCode=404');
        } else {
          history.replace(routes.ERROR);
        }
      }
      return res;
    },
    initialState: {},
  });

  return { reviewTask, userReview, isLoading };
};

const useReviewTaskOutside = ({ token }: { token: string }) => {
  const history = useHistory();

  const [{ reviewTask, userReview }, isLoading, refetch] = useAsyncFetch<{
    reviewTask?: WithReplace<IPopulatedReviewTask, { userFrom: IUser }>;
    userReview?: IPopulatedUserReview;
  }>({
    fetch: async () => {
      let res = {};
      try {
        const response = await getReviewTaskOutside(token);

        res = {
          reviewTask: response.data.task,
          userReview: response.data.userReview,
        };
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404) {
          history.replace(routes.ERROR + '?errorCode=404');
        }
      }
      return res;
    },
    initialState: {},
  });

  return { reviewTask, userReview, isLoading, refetch };
};

export { useReviewTask, useReviewTaskOutside };
